<template>
  <body class="registration_wraapper">
    <!-- <div class="frames">
            <img src="./../assets/front/image/mobile_frame2.png" class="bottom_frame">
            <img src="./../assets/front/image/mobile_frame4.png">
        </div>-->

    <div class="frame">
      <div class="site-wrapper overflow-hidden">
        <section class="user_login h-100">
          <div class="row no-gutters align-items-center h-100">
            <div class="col-xl-4 offset-xl-7 col-lg-6 offset-lg-6 col-md-12">
              <h1>Sign in</h1>
              <p>Keep life moving.</p>
              <div v-if="error" class="alert alert-danger">{{ error }}</div>
              <validation-observer ref="simpleRules">
                <form id="signin-form" v-on:submit.prevent="validationForm">
                  <div class="form-group">
                    <label>Username or email</label>
                    <validation-provider name="Email" rules="required|email" #default="{ errors }">
                      <input id="email" type="email" name="email" v-model.trim="email"
                        :class="errors.length > 0 ? 'is-invalid' : 'is-valid'" class="form-control" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                  <div class="form-group">
                    <label>Password
                      <router-link to="/forgot" class="float-right">Forgot Password?</router-link>
                    </label>
                    <validation-provider name="Password" rules="required|password|min:5|max:20" #default="{ errors }">
                      <input type="password" name="password" v-model.trim="password"
                        :class="errors.length > 0 ? 'is-invalid' : 'is-valid'" class="form-control" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                  <p class="text-right"></p>
                  <p class="text-center mt-4">
                    <button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="btn btn-primary button_wraper">Sign
                      in</button>
                  </p>
                  <p class="text-center">
                    Not have an account?
                    <router-link to="/registration">Create Account </router-link>
                    <br /><br />
                    <router-link to="/" class="btn btn-primary backToHome button_wraper"><span>← </span> Back to
                      Home</router-link>
                  </p>
                </form>
              </validation-observer>
            </div>
          </div>
        </section>
      </div>
    </div>
  </body>
</template>

<script>
export default {};
</script>
<script>

//Vee-Validate 
import { ValidationProvider, ValidationObserver} from 'vee-validate';
import {
    required, email, confirmed, password
} from '@validations'
import Ripple from 'vue-ripple-directive'
import Vue from "vue";
import axios from "axios";
import firebase from "firebase";
import { db } from "@/main";
import store from '@/store'

import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: "Login",

  components: {
    ValidationProvider, 
    ValidationObserver,
    required,
     email, 
     confirmed, 
     password
  },

  directives: {
    Ripple,
  },
  data: function() {
    return {
      title: "Sign-in",
      email: "",
      password: "",
      error: "",
    };
  },

  created: function () {
    document.title = this.title;
    document.getElementById("google_translate_element").style.display = "none";
  },
  
  methods: {

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
          if (success) {
              // eslint-disable-next-line
              this.submit();
          }
      })
    },

    submit: function() {
      // localStorage.clear();
      store.dispatch('app/commitActivateLoader');
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then((data) => {
          console.log(data);
          //this.$router.replace({ name: "Dashboard" });
          //console.log(data.user.uid);
          //console.log('ggg');
          this.redirect_url  = "";

          db.collection("users")
            .where("user_id", "==", data.user.uid)
            .get()
            .then((querySnapshot) => {
              store.dispatch('app/commitDeactivateLoader');
              querySnapshot.forEach((doc) => {
                console.log(doc.id, " => ", doc.data());
                if (doc.data().status == 1) {
                  localStorage.setItem("Ustatus", doc.data().status);
                  localStorage.setItem("userSessionId", doc.id);
                  localStorage.setItem("userFirstname", doc.data().firstname);
                  let userData = 
                    {id: doc.id,
                    fullName: doc.data().firstname,
                    username: doc.data().firstname + '' + doc.data().lastname,
                    avatar: doc.data().image,
                    email: doc.data().email,
                    role: doc.data().status == 1 ? 'admin' : 'client',
                    ability:
                    [{
                      action:"manage",
                      subject:"all"
                    }],
                    "extras":{"eCommerceCartItemsCount":5}}
                  localStorage.setItem('userData', JSON.stringify(userData))
                  this.$ability.update(userData.ability)
                  // window.location = '/dashboard';
                  this.redirect_url = '/dashboard';
                } else {
                  localStorage.setItem("userSessionId", doc.id);
                  localStorage.setItem("userFirstname", doc.data().firstname);
                  let userData = 
                    {id: doc.id,
                    fullName: doc.data().firstname,
                    username: doc.data().firstname + '' + doc.data().lastname,
                    avatar: doc.data().image,
                    email: doc.data().email,
                    role: doc.data().status == 1 ? 'admin' : 'client',
                    ability:
                    [{
                      action:"manage",
                      subject:"all"
                    }],
                    "extras":{"eCommerceCartItemsCount":5}}
                  localStorage.setItem('userData', JSON.stringify(userData))
                  this.$ability.update(userData.ability)
				          localStorage.setItem("Ustatus",2);
                  // this.$router.push({ name: "userProfile" });
                  this.redirect_url = '/';
                }

                //cart start
                var uid = doc.id;
                // alert(uid);
                var randomId = localStorage.getItem("randomId");

                // this.random_id = randomId;
                db.collection("cart")
                  .where("random_id", "==", randomId)
                  .get()
                  .then((querySnapshot) => {
                    querySnapshot.forEach(function(docs) {
                      // doc.data() is never undefined for query doc snapshots
                      console.log(docs.id, " => ", docs.data());

                      //db.collection("cart").doc(doc.id).udpate({ uid: uid });
                      console.log(uid);

                      db.collection("cart")
                        .doc(docs.id)
                        .update({
                          uid: doc.id,
                        })
                        .then(() => {
                          console.log("Document successfully updated!");
                        })
                        .catch((error) => {
                          store.dispatch('app/commitDeactivateLoader');

                          // The document probably doesn't exist.
                          console.error("Error updating document: ", error);
                        });
                    });
                  });
                //cart end

                this.$router.push({ name: "userProfile" });

                // alert(this.redirect_url)
                // console.log(this.redirect_url)
                // var Return_url = localStorage.getItem("Return_url");
                //   if(Return_url != "" && Return_url != "/signin"){
                //     window.location = Return_url;
                //   }else{
                //     // this.$router.push({ name: "userProfile" });
                //     window.location = this.redirect_url;
                //   }
                  
              });
            })
            .catch((error) => {
              store.dispatch('app/commitDeactivateLoader');

              console.log("Error getting documents: ", error);
            });
        })
        .catch((err) => {
          store.dispatch('app/commitDeactivateLoader');
          console.log(err.message);
          this.error = err.message;
        });
    },
  },
};
</script>


<style scoped>
.user_login {
  height: 86vh;
}

.user_login h1 {
  font-size: 30px;
  margin-bottom: 20px;
}

.user_login form {
  margin-top: 50px;
}

.user_login .form-control {
  background: #f2f4f5 !important;
  height: 40px;
  border-color: #f2f4f5;
}

.user_login .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #dadcdc;
  outline: 0;
}

.user_login label {
  display: block;
}

.user_login .row.h-100 {
  position: relative;
}

.user_login .row.h-100:before {
  background: url(../../assets/front/image/sign_in.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  content: "";
  width: 50%;
  height: 100%;
}

.frames {
  position: absolute;
  height: 100vh;
}

.frames .bottom_frame {
  position: absolute;
  bottom: 0;
}

.user_login .row.h-100 .col-xl-4 {
  background: #fff;
  padding: 25px;
  border-radius: 5px;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.user_login .row.h-100 .col-xl-4::-webkit-scrollbar,
.frame:before,
.frame:after {
  display: none;
}

.site-wrapper {
  height: 100vh;
  border: none;
  border-radius: 0;
}

body {
  background: #fff;
}

.user_login {
  margin-top: 0;
}



.registration_wraapper::after,
.registration_wraapper::before {
  position: absolute;
  content: "";
  z-index: 9;
  transform: translateX(-50%);
}

.registration_wraapper::before {
  width: 120px;
  height: 10px;
  background: #fff;
  top: 32px;
  left: 50%;
  border-radius: 20px;
  opacity: .5;
}

.registration_wraapper::after {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  top: 29px;
  left: calc(50% - 90px);
  background: #898b8d;
}

.site-wrapper {
  margin: 0 auto;
  position: relative;
  border: 25px solid #14171b;
  border-top-width: 25px;
  border-bottom-width: 25px;
  border-top-width: 25px;
  border-bottom-width: 25px;
  border-radius: 119px;
  border-top-width: 72px;
  z-index: 8;
  border-bottom-width: 72px;
}


@media only screen and (max-width: 992px) {

  .frames,
  .user_login .row.h-100:before {
    display: none;
  }

  .user_login {
    height: 100vh;
    margin-top: 0;
  }

  .user_login>.row {
    background: none;
    padding: 0px 15px;
  }
}

@media only screen and (max-width: 600px) {
  .user_login .row.h-100 .col-xl-4 {
    justify-content: flex-start;
  }

  .site-wrapper.overflow-hidden {
    /* border: none; */
    border: block;
  }

  .registration_wraapper[data-v-72666184]::after,
  .registration_wraapper[data-v-72666184]::before {
    display: none;
  }

  /* ========== sign in page media query css code =========  */
  #app>div:nth-child(1)>main {
    height: 100%;
  }

  /* ========== sign in page media query css code =========  */
}

.frame>.site-wrapper.overflow-hidden {
  height: 100vh !important;
}

a.btn.btn-primary.backToHome.router-link-active {
  background: #ff9f43 !important;
  line-height: 27px;
}

.backToHome:hover {
  background: #f88300 !important;
}
.button_wraper {
    /* / background: #03e1bc !important; / */
    display: inline-block;
    width: 160px;
    text-align: center;
    border-radius: 50px !important;
    height: 50px;
    /* / line-height: 30px; / */
}
</style>
